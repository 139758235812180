import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  styled,
  Stack,
  useMediaQuery,
  Box,
} from '@mui/material';
import * as Redux from 'react-redux';
import * as IMG from '../../../static/images';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../constants';
import { useTheme } from '@emotion/react';
import MenuMobileComponent from './MenuMobile';
import { Link } from 'react-scroll';
import MenuOcultoWeb from './MenuOcultoWeb';
import ButtonLang from './ButtonLang';
import ButtonLink from './ButtonLink';
import { facebook, instagram } from '../LandingView';
import { COLORS } from '../../../helpers';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 2.2vw',
});

const NavbarView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const landingStore = Redux.useSelector((state) => state.landing);
  const [menuActiveColor, setMenuActiveColor] = useState(true);
  const [botonActiveColor, setBotonActiveColor] = useState(true);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      setIsScrolled(scroll > 200);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const inicioHeigth =
      document.getElementById(MODULES.inicio)?.offsetHeight ?? 0;
    const productosHeigth =
      document.getElementById(MODULES.productos)?.offsetHeight ?? 0;
    const familiaHeigth =
      document.getElementById(MODULES.familia)?.offsetHeight ?? 0;
    const obaHeigth =
      document.getElementById(MODULES.obamacare)?.offsetHeight ?? 0;
    const somHeigth = document.getElementById(MODULES.somos)?.offsetHeight ?? 0;
    const sociosHeigth =
      document.getElementById(MODULES.socios)?.offsetHeight ?? 0;
    const cTopHeigth =
      document.getElementById(MODULES.contactoTop)?.offsetHeight ?? 0;

    const handleScroll = () => {
      const scroll = window.scrollY;

      const inicioMax = inicioHeigth;
      const prodMax = productosHeigth + inicioMax;
      const familiaMax = familiaHeigth + prodMax;
      const obaMax = obaHeigth + familiaMax;
      const somMax = somHeigth + obaMax;
      const sociosMax = sociosHeigth + somMax;
      const cTopMax = cTopHeigth + sociosMax;

      if (
        (scroll >= 0 && scroll < inicioMax) ||
        (scroll > inicioMax && scroll < prodMax - 20) ||
        (scroll > obaMax - 20 && scroll < somMax - 20) ||
        (scroll > sociosMax - 20 && scroll < cTopMax - 80)
      ) {
        setMenuActiveColor(true);
      } else {
        setMenuActiveColor(false);
      }

      if (
        (scroll >= 0 && scroll < prodMax - productosHeigth / 1.2) ||
        (scroll > obaMax - somHeigth / 2.3 &&
          scroll < somMax - inicioHeigth / 1.1) ||
        (scroll > sociosMax - somHeigth / 2.4 &&
          scroll < cTopMax - cTopHeigth + 200)
      ) {
        setBotonActiveColor(true);
      } else {
        setBotonActiveColor(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const faceButton = (
    <Box
      component={'a'}
      sx={{
        position: 'fixed',
        bottom: isMobile ? '15px' : '7vw',
        right: isMobile ? '15px' : '2vw',
        height: isMobile ? '55px' : '4vw',
        width: isMobile ? '55px' : '4vw',
        borderRadius: '1vw',
        display: isMobile ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 1000,
      }}
      target="_blank"
      onClick={() => {
        window.open(
          facebook,
          '_blank' // Abre el enlace en una nueva pestaña
        );
      }}
    >
      <img
        src={botonActiveColor ? IMG.faceAzulPng : IMG.facePng}
        alt="Wasa"
        style={{
          width: isMobile ? '35px' : '4vw',
        }}
      />
    </Box>
  );

  const instaButton = (
    <Box
      component={'a'}
      sx={{
        position: 'fixed',
        bottom: isMobile ? '15px' : '2vw',
        right: isMobile ? '15px' : '2vw',
        height: isMobile ? '55px' : '4vw',
        width: isMobile ? '55px' : '4vw',
        borderRadius: '1vw',
        display: isMobile ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 1000,
      }}
      target="_blank"
      onClick={() => {
        window.open(
          instagram,
          '_blank' // Abre el enlace en una nueva pestaña
        );
      }}
    >
      <img
        src={botonActiveColor ? IMG.instaAzulPng : IMG.instaPng}
        alt="Wasa"
        style={{
          width: isMobile ? '35px' : '4vw',
        }}
      />
    </Box>
  );

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          height: isMobile ? '15vw' : '8vw',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          zIndex: 1000,
        }}
        elevation={0}
      >
        {isMobile ? (
          <MenuMobileComponent landingStore={landingStore} />
        ) : (
          <StyledToolbar>
            <img
              src={IMG.logotipoSvg}
              alt="headerImg"
              style={{
                width: isMobile ? '20vw' : '10vw',
                opacity: isScrolled ? 0 : 1,
                transition: 'opacity 0.5s ease-in-out',
              }}
            />

            <>
              <MenuOcultoWeb
                menuActiveColor={menuActiveColor}
                sx={{
                  opacity: isScrolled ? 1 : 0,
                  transition: 'opacity 0.5s ease-in-out',
                }}
              />
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  opacity: isScrolled ? 0 : 1,
                  transition: 'opacity 0.5s ease-in-out',
                  zIndex: isScrolled ? 0 : 1000,
                }}
              >
                <ButtonLink>
                  <Link
                    to={MODULES.contactoCenter} // El id del elemento al que quieres desplazarte
                    smooth={true}
                    duration={500}
                    style={{
                      fontFamily: 'bold',
                    }}
                  >
                    {t('navbar.contacto')}
                  </Link>
                </ButtonLink>
                <ButtonLang
                  hover={{
                    backgroundColor: COLORS.BLANCO,
                    color: COLORS.AZUL_OSCURO,
                  }}
                />
              </Stack>
            </>
          </StyledToolbar>
        )}
      </AppBar>
      {faceButton}
      {instaButton}
    </>
  );
};

export default NavbarView;
