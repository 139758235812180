import React from 'react';
import { COLORS } from '../../../helpers';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import * as IMG from '../../../static/images';
import { Link } from 'react-scroll';
import { MODULES } from '../../../constants';
import { useTranslation } from 'react-i18next';
import ButtonLang from './ButtonLang';
import ButtonLink from './ButtonLink';

const MenuOcultoWeb = ({ sx, menuActiveColor }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '91%',
        zIndex: 1000,
        borderRadius: '4vw',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.2vw',
        transition: 'background-color 0.5s ease-in-out',
        backgroundColor: menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO,
        ...sx,
      }}
    >
      <img
        src={!menuActiveColor ? IMG.logotipoAzulPng : IMG.logotipoSvg}
        alt="headerImg"
        style={{
          width: isMobile ? '20vw' : '8vw',
        }}
      />
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Link
          to={MODULES.inicio} // El id del elemento al que quieres desplazarte
          smooth={true}
          duration={500}
        >
          <ButtonLink
            color={!menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO}
          >
            {t('navbar.inicio')}
          </ButtonLink>
        </Link>
        <Link
          to={MODULES.contactoCenter} // El id del elemento al que quieres desplazarte
          smooth={true}
          duration={500}
        >
          <ButtonLink
            color={!menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO}
            mr="1vw"
            onClick={() => {}}
          >
            {t('navbar.contacto')}
          </ButtonLink>
        </Link>
        <ButtonLang
          color={menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO}
          bgColor={menuActiveColor ? COLORS.BLANCO : COLORS.AZUL_OSCURO}
        />
      </Stack>
    </Box>
  );
};

export default MenuOcultoWeb;
