import React from 'react';
import { Box } from '@mui/material';
import InicioView from './1-inicio';
import ProductosView from './2-productos';
import FamiliaView from './3-familia';
import ObamacareView from './4-obamacare';
import SomosView from './5-somos';
import SociosView from './6-socios';
import ContactoView from './7-contacto';
import NavbarView from './navbar';

export const numberPhone = '+13058333232';
export const wasaMobile = `whatsapp://send?phone=${numberPhone}text=Necesito%20consultar%20sobre...`;
export const wasaDesktop = `https://web.whatsapp.com/send?phone=${numberPhone}&text=Necesito%20consultar%20sobre...`;
export const facebook =
  'https://www.facebook.com/profile.php?id=100070444596130';
export const instagram = 'https://www.instagram.com/ceram_insurance';

export const onClickWasa = (isMobile) => {
  window.open(
    isMobile ? wasaMobile : wasaDesktop,
    '_blank' // Abre el enlace en una nueva pestaña
  );
};

const LandingView = () => {
  return (
    <Box position="relative">
      <NavbarView />
      <InicioView />
      <ProductosView />
      <FamiliaView />
      <ObamacareView />
      <SomosView />
      <SociosView />
      <ContactoView />
    </Box>
  );
};

export default LandingView;
