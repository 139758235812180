import React from 'react';
import TopView from './1-top';
import CenterView from './2-center';
import FooterView from './3-footer';
import FormView from './form';
import { Stack } from '@mui/material';

const view = () => {
  return (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <TopView />
      <CenterView />
      <FooterView />
      <FormView />
    </Stack>
  );
};

export default view;
