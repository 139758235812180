export const APP_NAME = 'ceramInsurance';
export const TOKEN = `${APP_NAME}Token`;
export const EMAIL = `${APP_NAME}Email`;
export const I18N = `${APP_NAME}I18n`;
export const I18N_LANG = `${APP_NAME}I18Lng`;
export const REMEMBER_ME = `${APP_NAME}RemenberMe`;
export const PERSIST = `persist:${APP_NAME}`;

export const device = {
  mobile: '(min-width: 280px) and (max-width: 719px)',
  tablet: '(min-width: 720px) and (max-width: 980px)',
  laptop: '(min-width: 981px) and (max-width: 1199px)',
  desktop: '(max-width: 1200px)',
};

export const MODULES = {
  inicio: 'inicio',
  productos: 'productos',
  familia: 'familia',
  obamacare: 'obamacare',
  obamacareCardMobile: 'obamacareCardMobile',
  obamacareBodyMobile: 'obamacareBodyMobile',
  somos: 'somos',
  somosCardMobile: 'somosCardMobile',
  somosBodyMobile: 'somosBodyMobile',
  socios: 'socios',
  servicios: 'servicios',
  contactoTop: 'contactoTop',
  contactoCenter: 'contactoCenter',
  contactoFooter: 'contactoFooter',
};
