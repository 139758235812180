import React from 'react';
import Grid from '@mui/material/Grid';
import LandingView from './LandingView';

const index = () => {
  return (
    <Grid container spacing={0}>
      <Grid item md={12}>
        <LandingView />
      </Grid>
    </Grid>
  );
};

export default index;
