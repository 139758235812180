import React from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { COLORS } from '../../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../../constants';
import * as IMG from '../../../../static/images';
import CardServicios from './CardServicios';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? '1000px' : '110vh',
    flexDirection: 'column',
    backgroundColor: COLORS.GRIS,
  }));

  const servicios = [
    {
      title: t('contacto.top.service1'),
      img: IMG.beneficio1Png,
      white: IMG.beneficio1whitePng,
      wd: '6vw',
      wdMobile: '70px',
      delay: 100,
    },
    {
      title: t('contacto.top.service2'),
      img: IMG.beneficio2Png,
      white: IMG.beneficio2whitePng,
      wd: '4vw',
      wdMobile: '45px',
      delay: 300,
    },
    {
      title: t('contacto.top.service3'),
      img: IMG.beneficio3Png,
      white: IMG.beneficio3whitePng,
      wd: '4vw',
      wdMobile: '45px',
      delay: 500,
    },
    {
      title: t('contacto.top.service4'),
      img: IMG.beneficio4Png,
      white: IMG.beneficio4whitePng,
      wd: '5vw',
      wdMobile: '55px',
      delay: 700,
    },
    {
      title: t('contacto.top.service5'),
      img: IMG.beneficio5Png,
      white: IMG.beneficio5whitePng,
      wd: '5vw',
      wdMobile: '55px',
      delay: 1000,
    },
    {
      title: t('contacto.top.service6'),
      img: IMG.beneficio6Png,
      white: IMG.beneficio6whitePng,
      wd: '5.5vw',
      wdMobile: '70px',
      delay: 1200,
    },
  ];

  return (
    <CustomBox id={MODULES.contactoTop} component="div">
      <Stack
        direction="column"
        alignContent="center"
        justifyContent="center"
        sx={{
          width: '85vw',
        }}
      >
        <Typography
          fontSize={isMobile ? '18pt' : '3vw'}
          fontFamily="medium"
          align="center"
          color={COLORS.NEGRO}
          sx={{ mb: 3 }}
        >
          {isMobile ? (
            'En Ceram Insurance hacemos la mayor parte del trabajo por ti...'
          ) : (
            <>
              {t('contacto.top.title')} <br />
              {t('contacto.top.title2')}
            </>
          )}
        </Typography>
        <Typography
          fontSize={isMobile ? '12pt' : '1.5vw'}
          fontFamily="regular"
          align="center"
          color={COLORS.NEGRO}
          sx={{
            mb: isMobile ? '20px' : 0,
          }}
        >
          {t('contacto.top.text')}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          height={isMobile ? 'auto' : '20vw'}
          sx={{
            m: '2vw 0',
            flexWrap: 'wrap',
          }}
        >
          {servicios.map((servicio, index) => (
            <div
              key={`services-${index}`}
              data-aos={isMobile ? ' ' : 'flip-left'}
              data-aos-duration="1000"
              data-aos-delay={servicio.delay}
            >
              <CardServicios isMobile={isMobile} data={servicio} />
            </div>
          ))}
        </Stack>
      </Stack>
    </CustomBox>
  );
};

export default View;
