import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

const CardFoto = ({ item, index, isMobile }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      data-aos="fade-down"
      data-aos-duration="1500"
      data-aos-delay={item.delay}
      style={{
        margin: 0,
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        onMouseEnter={() => (isMobile ? null : setHover(true))}
        onMouseLeave={() => (isMobile ? null : setHover(false))}
        sx={{
          width: isMobile ? '50px' : '7vw',
        }}
      >
        <Typography
          fontFamily="medium"
          fontSize={isMobile ? '14pt' : '1.8vw'}
          sx={{
            transition: 'display 0.5s, opacity 0.5s',
            opacity: hover ? 1 : 0,
          }}
        >
          {item.name}
        </Typography>
        <img
          src={item.img}
          alt={`foto-${index}`}
          style={{
            transition: 'width 0.5s',
            width: hover
              ? isMobile
                ? '90px'
                : '8vw'
              : isMobile
                ? '50px'
                : '5vw',
          }}
        />
      </Stack>
    </div>
  );
};

export default CardFoto;
