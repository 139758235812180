import React from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../../static/images';
import { COLORS } from '../../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../../constants';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: isMobile ? 'flex-end' : 'center',
    alignItems: isMobile ? 'center' : 'center',
    height: isMobile ? '800px' : '40vh',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: COLORS.AZUL_OSCURO,
  }));

  return (
    <CustomBox id={MODULES.contactoFooter} component="div">
      <Stack direction="column" alignContent="center" justifyContent="center">
        <Box
          sx={{
            height: isMobile ? '80px' : '5vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: isMobile ? '0' : '1vw',
          }}
        >
          <img
            src={IMG.logotipoSvg}
            alt="logo"
            style={{ width: isMobile ? '100px' : '10vw' }}
          />
        </Box>
        <Typography
          fontFamily="regular"
          color={COLORS.BLANCO}
          fontSize={isMobile ? '9pt' : '1.1vw'}
          textAlign="center"
          sx={{
            mb: isMobile ? '60px' : 0,
          }}
        >
          {t('footer.text')}
        </Typography>
      </Stack>
    </CustomBox>
  );
};

export default View;
