const generateRandomNumber = (min = 100000000000000, max = 999999999999999) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const COLORS = {
  AZUL_OSCURO: '#232B9A',
  AMARILLO_OSCURO: '#D7BD5D',
  GRIS: '#E9EAF5',
  GRIS_OSCURO: '#B6B7C9',
  BLANCO: '#FFFFFF',
  NEGRO: '#000000',
  VERDE_WASA: '#36B669',
};

export { generateRandomNumber, COLORS };
