import { Stack, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../helpers';
import { arrowDownPng } from '../../../static/images';

const CardMobile = ({ open, setOpen, text, img, delay }) => {
  return (
    <Stack
      sx={{
        width: '100vw',
        height: '230px',
        backgroundImage: `url(${img})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      alignItems="center"
      justifyContent="center"
      onClick={() => setOpen(!open)}
    >
      <Typography
        fontSize="20pt"
        fontFamily="medium"
        color={COLORS.BLANCO}
        align="center"
      >
        {text}
        <br />
        <img
          src={arrowDownPng}
          alt="obamacare"
          style={{
            width: '60px',
          }}
        />
      </Typography>
    </Stack>
  );
};

export default CardMobile;
