import { Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../helpers';

const ButtonAzul = ({ onClick, children }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      sx={{
        height: isMobile ? '50px' : '4vw',
        borderRadius: isMobile ? '6.5vw' : '2vw',
        backgroundColor: COLORS.AZUL_OSCURO,
        color: COLORS.BLANCO,
        textTransform: 'none',
        transition:
          'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
        '&:hover': {
          backgroundColor: COLORS.BLANCO,
          color: COLORS.NEGRO,
          transform: 'scale(1.02)',
        },
        paddingLeft: isMobile ? '5vw' : '2vw',
        paddingRight: isMobile ? '5vw' : '2vw',
        fontFamily: 'Bold',
        fontSize: isMobile ? '14pt' : '1.5vw',
        marginTop: isMobile ? '3vw' : '2vw',
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonAzul;
