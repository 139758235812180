import React from 'react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { MODULES } from '../../../constants';
import ButtonAzul from './ButtonAzul';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? '80vh' : '100vh',
    backgroundImage: `url(${IMG.headerPng})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: COLORS.AZUL_OSCURO,
  }));

  return (
    <CustomBox id={MODULES.inicio} component="header">
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
          }}
        >
          <Typography
            variant="h1"
            color="white"
            align="center"
            sx={{
              fontFamily: 'bold',
              fontSize: isMobile ? '8vw' : '4vw',
              my: isMobile ? '2vw' : '1vw',
              mx: isMobile ? '5vw' : '1vw',
            }}
            data-aos={isMobile ? '' : 'fade-down'}
            data-aos-duration="1000"
            data-aos-delay="0"
          >
            {t('header.text')}
            <br />
            {t('header.text2')}
          </Typography>
          <Link
            to={MODULES.contactoCenter} // El id del elemento al que quieres desplazarte
            smooth={true}
            duration={500}
            data-aos={isMobile ? '' : 'fade-down'}
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <ButtonAzul>{t('header.plan')}</ButtonAzul>
          </Link>
        </Box>
      </Box>
    </CustomBox>
  );
};

export default View;
