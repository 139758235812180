import React from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../constants';
import ScrollCarousel from 'scroll-carousel-react';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? '300px' : '23vw',
    width: isMobile ? '100vw' : '100%',
    flexDirection: 'column',
    backgroundColor: COLORS.AZUL_OSCURO,
  }));

  const array = Array.from(
    { length: 23 },
    (_, i) => IMG[`carrie${String(i + 1).padStart(3, '0')}`]
  );

  return (
    <CustomBox id={MODULES.socios} component="div">
      <Typography
        fontFamily="medium"
        fontSize={isMobile ? '20pt' : '2.5vw'}
        color={COLORS.BLANCO}
        sx={{ mb: isMobile ? '80px' : 6 }}
      >
        {t('socio.title')}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{
          height: '6vw',
          width: isMobile ? '100vw' : '100%',
        }}
      >
        <ScrollCarousel autoplay speed={5} autoplaySpeed={5}>
          {array.map((item, index) => (
            <img
              key={`socios-${index}`}
              src={item}
              alt="Obamacare"
              style={{ width: isMobile ? '150px' : '10vw', marginRight: '4vw' }}
            />
          ))}
        </ScrollCarousel>
      </Stack>
    </CustomBox>
  );
};

export default View;
