import { useTheme } from '@emotion/react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MODULES } from '../../../constants';
import { COLORS } from '../../../helpers';
import * as IMG from '../../../static/images';
import ButtonAzul from '../1-inicio/ButtonAzul';
import CardMobile from '../4-obamacare/CardMobile';
import { onClickWasa } from '../LandingView';
import CardFoto from './CardFoto';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!open) return setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: COLORS.GRIS,
    padding: '10vw 15vw',
  }));

  const array = [
    { name: 'Celia', img: IMG.celiaPng, delay: 200 },
    { name: 'Miladys', img: IMG.miladysPng, delay: 400 },
    { name: 'Yodaimys', img: IMG.yodaimysPng, delay: 600 },
    { name: 'Dayana', img: IMG.dayanaPng, delay: 800 },
    { name: 'Andres', img: IMG.andresPng, delay: 1000 },
    { name: 'Jesmarina', img: IMG.jesmarinaPng, delay: 1200 },
    { name: 'Randy', img: IMG.randyPng, delay: 1400 },
    { name: 'Hiram', img: IMG.hiramPng, delay: 1600 },
    { name: 'Noleida', img: IMG.noleidaPng, delay: 1800 },
    { name: 'Margarita', img: IMG.margaritaPng, delay: 2000 },
  ];

  const title = t('somos.title');

  const texto = (
    <Typography
      fontSize={isMobile ? '14pt' : '1.3vw'}
      fontFamily="regular"
      color={COLORS.NEGRO}
      sx={{
        m: isMobile ? '40px' : '3vw 0',
        mb: isMobile ? '10px' : 0,
      }}
    >
      {t('somos.text')}
      <br />
      <br />
      {t('somos.text1')}
      <br />
      <br />
      {t('somos.text2')}
    </Typography>
  );

  const footer = (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{
          flexWrap: isMobile ? 'wrap' : null,
          justifyContent: 'space-between',
          display: isMobile ? 'inline-flex' : null,
          height: isMobile ? '130px' : '12vw',
          mt: isMobile ? 0 : 7,
          width: isMobile ? '290px' : null,
        }}
      >
        {array.map((item, index) => (
          <CardFoto
            key={`foto-${index}index}`}
            item={item}
            index={index}
            isMobile={isMobile}
          />
        ))}
      </Stack>
      <br />
      <br />
      <br />
      <Typography
        fontFamily="medium"
        fontSize={isMobile ? '22pt' : '3vw'}
        color={COLORS.NEGRO}
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="600"
      >
        {t('somos.listo')}
      </Typography>
      <div data-aos="fade-down" data-aos-duration="1000" data-aos-delay="800">
        <ButtonAzul onClick={() => onClickWasa(isMobile)}>
          {t('navbar.contacto')}
        </ButtonAzul>
      </div>
    </Stack>
  );

  return isMobile ? (
    <Stack direction="column" width="100vw">
      <CardMobile
        id={MODULES.somosCardMobile}
        text={title}
        open={open}
        setOpen={setOpen}
        img={IMG.aboutusPng}
        delay={400}
      />
      <Stack
        id={MODULES.somosBodyMobile}
        sx={{
          backgroundColor: COLORS.GRIS,
          height: open ? '1500px' : 0,
          transition: 'height 0.2s ease-in-out',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            display: show ? 'flex' : 'none',
            flexDirection: 'column',
          }}
        >
          {texto}
          {footer}
        </Box>
      </Stack>
    </Stack>
  ) : (
    <CustomBox id={MODULES.somos} component="div">
      <Stack
        sx={{
          width: '100%',
        }}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={IMG.logotipoAzulPng}
          alt="Obamacare"
          style={{ width: '13vw', marginBottom: '4vw' }}
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="100"
        />
        <Typography
          color={COLORS.AZUL_OSCURO}
          fontFamily="medium"
          fontSize="4vw"
          sx={{
            mb: 3,
          }}
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          {title}
        </Typography>
        {texto}
        {footer}
      </Stack>
    </CustomBox>
  );
};

export default View;
