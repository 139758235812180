import React from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../constants';
import ButtonWasa from './ButtonWasa';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? 'auto' : '90vh',
    backgroundColor: COLORS.AZUL_OSCURO,
    flexDirection: 'column',
  }));

  return (
    <CustomBox id={MODULES.familia} component="header">
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignContent={isMobile ? 'flex-start' : 'center'}
        justifyContent={isMobile ? 'flex-start' : 'spapce-between'}
        sx={{
          width: isMobile ? '100vw' : '85vw',
        }}
      >
        {isMobile && (
          <Stack
            sx={{
              position: 'relative',
              width: '100%',
              height: '30vh',
              backgroundColor: COLORS.AZUL_OSCURO,
              objectFit: 'cover',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={IMG.familia2Png}
              alt="familia2"
              style={{
                width: '100%',
                height: '30vh',
                objectFit: 'cover',
              }}
            />
            <Typography
              sx={{
                position: 'absolute',
                padding: '30px',
              }}
              fontSize="18pt"
              fontFamily="medium"
              color={COLORS.BLANCO}
              textAlign="center"
            >
              Lo fundamental para nosotros es garantizar la salud y estabilidad
              financiera de tu familia
            </Typography>
          </Stack>
        )}

        <Stack
          direction="column"
          alignItems="flex-start"
          sx={{
            width: isMobile ? '100vw' : '60%',
          }}
        >
          {!isMobile && (
            <Typography
              fontSize="2vw"
              fontFamily="medium"
              color={COLORS.BLANCO}
            >
              {t('familia.title')}
            </Typography>
          )}
          <Typography
            fontSize={isMobile ? '13pt' : '1.4vw'}
            fontFamily="regular"
            color={COLORS.BLANCO}
            sx={{
              m: isMobile ? '40px' : '3vw 0',
              mb: isMobile ? '10px' : '2vw',
            }}
          >
            {t('familia.text')}
          </Typography>
          <Box
            sx={{
              width: isMobile ? '100%' : null,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: isMobile ? '50px' : '0',
            }}
          >
            <ButtonWasa t={t} />
          </Box>
        </Stack>
        {!isMobile && (
          <Stack width="50%" alignItems="end">
            <Box width="75%">
              <img
                src={IMG.familiaPng}
                alt="familia"
                style={{ width: '100%' }}
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="300"
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </CustomBox>
  );
};

export default View;
