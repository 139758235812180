import { CLEAR_STORE } from '../../store/Type';

export const ALL_LANDING = 'ALL_LANDING';
export const LOADING_LANDING = 'LOADING_ALL_LANDING';
export const ACTIVO_LANDING = 'ACTIVO_LANDING';
export const PARAMS_LANDING = 'PARAMS_LANDING';
export const IS_MENU_LANDING = 'IS_MENU_LANDING';
export const LANG_LANDING = 'LANG_LANDING';

const initialState = {
  all: [],
  loading: false,
  activo: null,
  params: {},
  isMenu: false,
  lang: 'es',
};

export const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_LANDING:
      return {
        ...state,
        all: action.payload.data,
      };
    case LOADING_LANDING:
      return {
        ...state,
        loading: action.payload,
      };
    case LANG_LANDING:
      return {
        ...state,
        lang: action.payload,
      };

    case ACTIVO_LANDING:
      return {
        ...state,
        activo: action.payload,
      };

    case PARAMS_LANDING:
      return {
        ...state,
        params: action.payload,
      };

    case IS_MENU_LANDING:
      return {
        ...state,
        isMenu: action.payload,
      };
    case CLEAR_STORE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
