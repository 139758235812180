import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { I18N_LANG } from '../../../constants';
import { COLORS } from '../../../helpers';
import { LANG_LANDING } from '../LandingReducer';

export const languages = ['es', 'en'];

export const getNextLanguage = (currentLanguage) => {
  const currentIndex = languages.indexOf(currentLanguage);
  const nextIndex = (currentIndex + 1) % languages.length;
  return languages[nextIndex];
};

const ButtonLang = ({
  color = COLORS.BLANCO,
  bgColor = COLORS.AZUL_OSCURO,
  isMobile = false,
  hover,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState('es');

  const changeLang = () => {
    const nextLang = getNextLanguage(lang);
    setLang(nextLang);
    dispatch({
      type: LANG_LANDING,
      payload: nextLang,
    });
    i18n.changeLanguage(nextLang);
  };
  useEffect(() => {
    const lang = window.localStorage.getItem(I18N_LANG);
    if (lang) setLang(lang);
  }, []);

  return (
    <Button
      sx={{
        color: color,
        textTransform: 'none',
        fontFamily: 'bold',
        fontSize: isMobile ? '14pt' : '1.2vw',
        height: isMobile ? 'auto' : '3.1vw',
        width: isMobile ? 'auto' : '9vw',
        borderRadius: isMobile ? '10vw' : '2vw',
        backgroundColor: bgColor,
        transition: 'transform 0.3s ease-in-out',
        '&:hover': hover
          ? {
              ...hover,
              transform: 'scale(1.05)',
            }
          : {
              backgroundColor: bgColor,
              transform: 'scale(1.05)',
            },
      }}
      onClick={changeLang}
    >
      {t('langEs')}
    </Button>
  );
};

export default ButtonLang;
