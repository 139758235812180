import { Box, Button } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../helpers';

const ButtonLink = ({ color = COLORS.BLANCO, mr, children }) => {
  return (
    <Box
      sx={{
        marginRight: mr,
        transition: 'border-bottom 0.3s ease-in-out',
        '&:hover': {
          borderBottom: `0.2vw solid ${color}`,
        },
      }}
    >
      <Button
        sx={{
          color: color,
          fontSize: '1.2vw',
          height: '3.1vw',
          paddingLeft: '1.3vw',
          paddingRight: '1.3vw',
          fontFamily: 'bold',
          transition: 'transform 0.5s ease-in-out, color 0.5s ease-in-out',
          borderRadius: '0',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            transform: 'scale(1)',
            borderRadius: '0',
          },
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

export default ButtonLink;
