import React from 'react';
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../constants';
import CardProductos from './CardProductos';
import ButtonAzul from '../1-inicio/ButtonAzul';
import { onClickWasa } from '../LandingView';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? '650px' : '110vh',
    backgroundColor: COLORS.GRIS,
    flexDirection: 'column',
  }));

  const productos = [
    {
      title: t('productos.salud'),
      wd: '50%',
      img: IMG.saludPng,
      color: COLORS.AZUL_OSCURO,
      delay: '200',
    },
    {
      title: t('productos.vida'),
      wd: '50%',
      img: IMG.vidaPng,
      color: COLORS.AMARILLO_OSCURO,
      delay: '400',
    },
    {
      title: t('productos.comple'),
      wd: '90%',
      img: IMG.carPng,
      color: COLORS.AZUL_OSCURO,
      delay: '600',
    },
    {
      title: t('productos.vision'),
      wd: '50%',
      img: IMG.dentalPng,
      color: COLORS.AMARILLO_OSCURO,
      delay: '800',
    },
  ];

  const mobile = (
    <Stack
      direction="column"
      alignContent="center"
      justifyContent="center"
      my="30px"
    >
      {productos.map((producto, index) => (
        <div
          key={`product-${index}`}
          data-aos={isMobile ? '' : 'fade-down'}
          data-aos-duration="1000"
          data-aos-delay={producto.delay}
        >
          <Button
            sx={{
              backgroundColor:
                producto.color === COLORS.AZUL_OSCURO
                  ? COLORS.GRIS_OSCURO
                  : COLORS.AMARILLO_OSCURO,
              color: COLORS.NEGRO,
              textTransform: 'none',
              fontFamily: 'regular',
              fontSize: '16pt',
              borderRadius: '30px',
              height: '60px',
              width: '85vw',
              marginTop: '3vw',
            }}
            onClick={() => onClickWasa(isMobile)}
          >
            {producto.title}
          </Button>
        </div>
      ))}
    </Stack>
  );

  return (
    <CustomBox id={MODULES.productos} component="header">
      <Typography
        fontSize={isMobile ? '24pt' : '3vw'}
        fontFamily="medium"
        color={COLORS.NEGRO}
      >
        {t('productos.text')}
      </Typography>
      {isMobile ? (
        mobile
      ) : (
        <Stack
          width="85%"
          height="27vw"
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ m: '2vw 0' }}
        >
          {productos.map((producto, index) => (
            <div
              data-aos="flip-left"
              data-aos-duration="1500"
              data-aos-delay={producto.delay}
            >
              <CardProductos key={`prod=${index}`} data={producto} />
            </div>
          ))}
        </Stack>
      )}

      <ButtonAzul onClick={() => onClickWasa(isMobile)}>
        {t('productos.more')}
      </ButtonAzul>
    </CustomBox>
  );
};

export default View;
