import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../../../helpers';
import { MODULES } from '../../../../constants';
import { Link } from 'react-scroll';

const CardServicios = ({ data, isMobile }) => {
  const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        bgcolor: COLORS.GRIS,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: isMobile ? '200px ' : '16vw',
        width: isMobile ? '40vw' : '12vw',
        flexDirection: 'column',
        borderRadius: '1vw',
        transition: 'background-color 0.5s, color 0.5s',
        cursor: 'pointer',
        '&:hover': {
          height: isMobile ? '220px' : '17vw',
          width: isMobile ? '42vw' : '13vw',
          backgroundColor: COLORS.AZUL_OSCURO,
          color: COLORS.BLANCO,
        },
        border: `0.1vw solid ${COLORS.AZUL_OSCURO}`,
        color: COLORS.AZUL_OSCURO,
        p: isMobile ? 0 : '0.5vw',
        mt: isMobile ? '2vw' : 0,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Stack
        sx={{
          width: '100%',
          height: isMobile ? '50px' : '8vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: isMobile ? data.wdMobile : data.wd,
            height: isMobile ? data.wdMobile : data.wd,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={hover ? data.white : data.img}
            alt="img"
            style={{ width: '100%' }}
          />
        </Box>
      </Stack>
      <Box
        width={isMobile ? '90%' : '70%'}
        height={isMobile ? '90px' : '4vw'}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          fontFamily="medium"
          fontSize={isMobile ? '12pt' : '1.1vw'}
          align="center"
          sx={{
            color: hover ? COLORS.BLANCO : COLORS.NEGRO,
          }}
        >
          {data.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardServicios;
