import React from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../../static/images';
import { COLORS } from '../../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { MODULES } from '../../../../constants';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'center',
    alignItems: 'center',
    height: isMobile ? '700px' : '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${IMG.contactoPng})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: COLORS.AZUL_OSCURO,
  }));

  const title = (text) => (
    <Typography
      fontFamily="medium"
      fontSize={isMobile ? '16pt' : '1.3vw'}
      color={COLORS.BLANCO}
      sx={{ mb: '0.5vw', width: '100%' }}
    >
      {text}
    </Typography>
  );

  const direccion = (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        width: '100%',
        mb: isMobile ? '20px' : '2vw',
      }}
      data-aos={isMobile ? '' : 'fade-down'}
      data-aos-duration="1000"
      data-aos-delay="300"
    >
      <img
        src={IMG.direccionPng}
        alt="direccion"
        style={{
          width: isMobile ? '17px' : '1.2vw',
          marginTop: isMobile ? '5px' : '0.5vw',
          marginRight: isMobile ? '3vw' : '1vw',
        }}
      />
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{ ml: isMobile ? '3vw' : '1vw' }}
      >
        {title(t('contacto.direccion'))}
        <Typography
          fontFamily="regular"
          fontSize={isMobile ? '10pt' : '1vw'}
          color={COLORS.BLANCO}
        >
          18191 NW 68 Ave suite 226
          <br />
          Miami Lakes Fl 33015
        </Typography>
      </Stack>
    </Stack>
  );

  const phone = (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        mb: isMobile ? '20px' : '2vw',
        width: '100%',
      }}
      data-aos={isMobile ? '' : 'fade-down'}
      data-aos-duration="1000"
      data-aos-delay="500"
    >
      <img
        src={IMG.phonePng}
        alt="phone"
        style={{
          width: isMobile ? '25px' : '2vw',
          marginTop: isMobile ? '5px' : '0.5vw',
        }}
      />
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{
          ml: isMobile ? '4vw' : '1vw',
        }}
      >
        {title(t('contacto.phone'))}

        <Typography
          fontFamily="regular"
          fontSize={isMobile ? '12pt' : '1vw'}
          color={COLORS.BLANCO}
        >
          305 833 3232
        </Typography>
      </Stack>
    </Stack>
  );

  const email = (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        mb: isMobile ? '20px' : '2vw',
        width: '100%',
      }}
      data-aos={isMobile ? '' : 'fade-down'}
      data-aos-duration="1000"
      data-aos-delay="700"
    >
      <img
        src={IMG.emailPng}
        alt="email"
        style={{
          width: isMobile ? '25px' : '2vw',
          marginTop: isMobile ? '5px' : '0.5vw',
        }}
      />
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{ ml: isMobile ? '4vw' : '1vw' }}
      >
        {title(t('contacto.email'))}
        <Typography
          fontFamily="regular"
          fontSize={isMobile ? '12pt' : '1vw'}
          color={COLORS.BLANCO}
        >
          ceraminsurance@gmail.com
        </Typography>
      </Stack>
    </Stack>
  );

  return (
    <CustomBox id={MODULES.contactoCenter} component="div">
      <Stack
        direction="row"
        alignContent="end"
        justifyContent={isMobile ? 'center' : 'flex-start'}
        sx={{
          width: '85vw',
        }}
      >
        <Stack
          direction="column"
          alignItems={isMobile ? 'center' : 'flex-start'}
          width={isMobile ? 'auto' : '45%'}
          sx={{
            mt: isMobile ? '80px' : '0',
          }}
        >
          <Typography
            fontSize={isMobile ? '30pt' : '3vw'}
            fontFamily="medium"
            color={COLORS.BLANCO}
            sx={{ mb: 4 }}
            textAlign="center"
            data-aos={isMobile ? '' : 'fade-down'}
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            {t('contacto.title')}
          </Typography>
          {direccion}
          {phone}
          {email}
        </Stack>
      </Stack>
    </CustomBox>
  );
};

export default View;
