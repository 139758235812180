import React, { useEffect, useState } from 'react';
import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import * as IMG from '../../../static/images';
import { COLORS } from '../../../helpers';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { I18N_LANG, MODULES } from '../../../constants';
import CardMobile from './CardMobile';

const View = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const CustomBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    backgroundImage: `url(${IMG.obamacarePng})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: COLORS.AZUL_OSCURO,
  }));

  const texto = (
    <Typography
      fontSize={isMobile ? '14pt' : '1.3vw'}
      fontFamily="regular"
      color={isMobile ? COLORS.NEGRO : COLORS.BLANCO}
      sx={{
        mx: isMobile ? '40px' : 0,
      }}
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-delay="300"
    >
      {t('obamacare.text1')}
      <br />
      <br />
      {t('obamacare.text2')}
      {window.localStorage.getItem(I18N_LANG) === 'en' && (
        <>
          <br />
          <br />
          {t('obamacare.text3')}
        </>
      )}
    </Typography>
  );

  useEffect(() => {
    if (!open) return setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return isMobile ? (
    <Stack direction="column" width="100vw">
      <CardMobile
        id={MODULES.obamacareCardMobile}
        text="¿Qué es Obamacare?"
        open={open}
        setOpen={setOpen}
        img={IMG.obamarecAmaPng}
        delay={100}
      />
      <Stack
        id={MODULES.obamacareBodyMobile}
        sx={{
          backgroundColor: COLORS.GRIS,
          height: open ? '650px' : 0,
          transition: 'height 0.2s ease-in-out',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: show ? 'flex' : 'none',
          }}
        >
          {texto}
        </Box>
      </Stack>
    </Stack>
  ) : (
    <CustomBox id={MODULES.obamacare} component="div">
      <Stack
        direction="row"
        alignContent="end"
        justifyContent="flex-end"
        sx={{
          width: '85vw',
        }}
      >
        <Box width="45%">
          <Typography
            fontSize="3vw"
            fontFamily="medium"
            color={COLORS.BLANCO}
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="100"
            sx={{
              mb: 3,
            }}
          >
            {t('obamacare.title')}
          </Typography>
          {texto}
        </Box>
      </Stack>
    </CustomBox>
  );
};

export default View;
