import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { I18N_LANG } from '../../../../constants';
import { COLORS } from '../../../../helpers';

const View = () => {
  const { t } = useTranslation();
  const [focusedInput, setFocusedInput] = useState('');
  const theme = useTheme();

  const landingStore = useSelector((state) => state.landing);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [fullname, setFullname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [code, setCode] = useState('');
  const [producto, setProducto] = useState(null);

  const [lang, setLang] = useState('es');

  React.useEffect(() => {
    const lang = window.localStorage.getItem(I18N_LANG);
    if (lang) setLang(lang);
  }, []);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleCorreoChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    if (!value || value === '') return setEmailError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError('Por favor, introduce un correo electrónico válido');
    } else {
      setEmailError('');
    }
  };

  const handleProductoChange = (event) => {
    setProducto(event.target.value);
  };

  const title = (text) => (
    <Typography
      fontFamily="medium"
      fontSize={isMobile ? '14pt' : '1.3vw'}
      color={COLORS.NEGRO}
      sx={{ mb: 2, mt: 4 }}
    >
      {text}
    </Typography>
  );

  const productos = [
    { id: 'health_insurance', nombre: t('productos.salud') },
    { id: 'life_insurance', nombre: t('productos.vida') },
    { id: 'supplemental_insurance', nombre: t('productos.comple') },
    { id: 'dental_And_vision', nombre: t('productos.vision') },
  ];
  const StyledArrowDropDownIcon = (props) => {
    return (
      <KeyboardArrowDownIcon
        {...props}
        style={{
          fontSize: '3vw',
          color: COLORS.AZUL_OSCURO,
        }}
      />
    );
  };

  const styles = {
    minWidth: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: isMobile ? '59%' : '43%',
        right: isMobile ? '8.5vw' : '10%',
        width: isMobile ? '80%' : '700px',
        height: isMobile ? '850px' : '720px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '3vw 2vw',
        borderRadius: '2vw',
      }}
    >
      {/* {title(t('contacto.form.fullname'))}
      <TextField
        fullWidth
        variant="outlined"
        focused={focusedInput === 'nombre'}
        onFocus={() => handleFocus('nombre')}
        onBlur={() => handleFocus('')}
        value={fullname}
        onChange={(e) => setFullname(e.target.value)}
        sx={{
          fontFamily: 'medium',
        }}
      />
      {title(t('contacto.form.phone'))}
      <ReactInputMask
        mask="(999) 999 9999"
        onFocus={() => handleFocus('telefono')}
        onBlur={() => handleFocus('')}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      >
        {() => (
          <TextField
            fullWidth
            variant="outlined"
            focused={focusedInput === 'telefono'}
            placeholder="(000) 000 0000"
            sx={{
              fontFamily: 'medium',
            }}
          />
        )}
      </ReactInputMask>
      {title(t('contacto.form.email'))}
      <TextField
        fullWidth
        variant="outlined"
        focused={focusedInput === 'correo'}
        onFocus={() => handleFocus('correo')}
        onBlur={() => handleFocus('')}
        placeholder="example@example.com"
        value={email}
        onChange={handleCorreoChange}
        sx={{
          fontFamily: 'medium',
        }}
      />
      {emailError !== '' && (
        <Typography
          fontFamily="regular"
          fontSize="1vw"
          color="red"
          sx={{ mt: 1 }}
        >
          {emailError}
        </Typography>
      )}
      {title(t('contacto.form.code'))}
      <ReactInputMask
        mask="99999"
        onFocus={() => handleFocus('codigoPostal')}
        onBlur={() => handleFocus('')}
        value={code}
        onChange={(e) => setCode(e.target.value)}
      >
        {() => (
          <TextField
            fullWidth
            variant="outlined"
            focused={focusedInput === 'codigoPostal'}
            placeholder="00000"
            sx={{
              fontFamily: 'medium',
            }}
          />
        )}
      </ReactInputMask>
      {title(t('contacto.form.product'))}
      <Select
        fullWidth
        variant="outlined"
        value={producto ?? ''}
        displayEmpty
        onChange={handleProductoChange}
        onFocus={() => handleFocus('producto')}
        onBlur={() => handleFocus('')}
        style={{
          color: producto !== null ? COLORS.NEGRO : 'rgba(0, 0, 0, 0.4)',
          fontFamily: 'medium',
        }}
        IconComponent={StyledArrowDropDownIcon}
      >
        <MenuItem value={''} disabled>
          {t('contacto.form.select')}
        </MenuItem>
        {productos.map((producto) => (
          <MenuItem key={producto.id} value={producto.id}>
            {producto.nombre}
          </MenuItem>
        ))}
      </Select>
      <Box marginTop={3}>
        <ButtonAzul onClick={() => {}}>{t('contacto.form.button')}</ButtonAzul>
      </Box> */}
      {/* <Box
        sx={{
          height: isMobile ? '80vw' : '53vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      > */}
      {landingStore.lang === 'es' ? (
        <iframe
          id="JotFormIFrame-240503995691060"
          title="Formulario Web Ceram ES"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/240503995691060"
          frameborder="0"
          style={styles}
          scrolling="no"
          allowTransparency="true"
        />
      ) : (
        <iframe
          id="JotFormIFrame-240705237295659"
          title="Formulario Web Ceram EN"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/240705237295659"
          frameborder="0"
          style={styles}
          scrolling="no"
          allowTransparency="true"
        />
      )}
      {/* </Box> */}
    </Box>
  );
};

export default View;
