import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../helpers';

const CardProductos = ({ data, index }) => {
  return (
    <Box
      sx={{
        bgcolor: data.color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '25vw',
        width: '19vw',
        flexDirection: 'column',
        borderRadius: '2vw',
        transition: 'width 0.5s, height 0.5s',
        '&:hover': {
          width: '21vw',
          height: '27vw',
        },
      }}
    >
      <Box
        sx={{
          width: '9vw',
          height: '9vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={data.img} alt="img" style={{ width: '100%' }} />
      </Box>
      <Box width={data.wd}>
        <Typography
          fontFamily="regular"
          fontSize="1.8vw"
          align="center"
          color={
            data.color === COLORS.AMARILLO_OSCURO ? COLORS.NEGRO : COLORS.BLANCO
          }
        >
          {data.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardProductos;
