import { Box, IconButton, Stack, keyframes, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import * as IMG from '../../../static/images';
import { Link } from 'react-scroll';
import * as Redux from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { MODULES } from '../../../constants';
import { IS_MENU_LANDING } from '../LandingReducer';
import { facebook, instagram } from '../LandingView';
import ButtonLang from './ButtonLang';

const zoom = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const MenuMobileComponent = ({ landingStore }) => {
  const dispatch = Redux.useDispatch();
  const { t } = useTranslation();

  const [show, setshow] = useState(false);
  const [menuActiveColor, setMenuActiveColor] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      const inicioHeigth =
        document.getElementById(MODULES.inicio)?.offsetHeight ?? 0;
      const productosHeigth =
        document.getElementById(MODULES.productos)?.offsetHeight ?? 0;
      const familiaHeigth =
        document.getElementById(MODULES.familia)?.offsetHeight ?? 0;
      const obaCardHeigth =
        document.getElementById(MODULES.obamacareCardMobile)?.offsetHeight ?? 0;
      const obaBodyHeigth =
        document.getElementById(MODULES.obamacareBodyMobile)?.offsetHeight ?? 0;
      const somosCardHeigth =
        document.getElementById(MODULES.somosCardMobile)?.offsetHeight ?? 0;
      const somosBodyHeigth =
        document.getElementById(MODULES.somosBodyMobile)?.offsetHeight ?? 0;
      const sociosHeigth =
        document.getElementById(MODULES.socios)?.offsetHeight ?? 0;
      const cTopHeigth =
        document.getElementById(MODULES.contactoTop)?.offsetHeight ?? 0;
      const ccEnterHeigth =
        document.getElementById(MODULES.contactoCenter)?.offsetHeight ?? 0;

      const inicioMax = inicioHeigth;
      const prodMax = productosHeigth + inicioMax;
      const famMax = familiaHeigth + prodMax;
      const obaCardMax = obaCardHeigth + famMax;
      const obaBodyMax = obaBodyHeigth + obaCardMax;
      const somosCardMax = somosCardHeigth + obaBodyMax;
      const somosBodyMax = somosBodyHeigth + somosCardMax;
      const sociosMax = sociosHeigth + somosBodyMax;
      const cTopMax = cTopHeigth + sociosMax;
      const cCenMax = ccEnterHeigth + cTopMax;

      const actProd = scroll < prodMax - 20;
      const actObaBody = scroll > obaCardMax + 220 && scroll < obaBodyMax + 210;
      const actSomosBody =
        scroll > somosCardMax + 450 && scroll < sociosMax + 150;
      const actTopBody = scroll > cTopMax - 550 && scroll < cCenMax - 250;

      if (actProd || actObaBody || actSomosBody || actTopBody) {
        setMenuActiveColor(true);
      } else {
        setMenuActiveColor(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!landingStore.isMenu) return setshow(landingStore.isMenu);
    setTimeout(() => setshow(landingStore.isMenu), 300);
  }, [landingStore.isMenu]);

  const faceButton = (
    <Box
      component={'a'}
      sx={{
        borderRadius: '1vw',
        cursor: 'pointer',
        mr: menuActiveColor ? 0 : 1,
      }}
      target="_blank"
      onClick={() => {
        window.open(facebook, '_blank');
      }}
    >
      <img
        src={IMG.faceAzulPng}
        alt="Wasa"
        style={{
          width: '50px',
        }}
      />
    </Box>
  );

  const instaButton = (
    <Box
      component={'a'}
      sx={{
        borderRadius: '1vw',
        cursor: 'pointer',
      }}
      target="_blank"
      onClick={() => {
        window.open(instagram, '_blank');
      }}
    >
      <img
        src={IMG.instaAzulPng}
        alt="Wasa"
        style={{
          width: '50px',
        }}
      />
    </Box>
  );

  const body = (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        width: '95%',
        height: '94%',
        display: show ? 'flex' : 'none',
      }}
    >
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            borderBottom: `0.5px solid ${
              !menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO
            }`,
            paddingBottom: '2vw',
            mt: '25px',
            mx: '2vw',
          }}
        >
          <Link
            to={MODULES.contactoCenter}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => {
              dispatch({
                type: IS_MENU_LANDING,
                payload: !landingStore.isMenu,
              });
            }}
            sx={{
              fontSize: '14pt',
            }}
          >
            <Typography
              fontFamily="bold"
              fontSize="14pt"
              sx={{
                color: !menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO,
              }}
            >
              {t('navbar.contacto')}
            </Typography>
          </Link>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            mt: '2vw',
          }}
        >
          {faceButton}
          {instaButton}
        </Stack>
      </Stack>
      <Box sx={{}}>
        <ButtonLang isMobile={true} />
      </Box>
    </Stack>
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 10,
        width: '100%',
        zIndex: '990',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          width: '90%',
          backgroundColor: menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO,
          borderRadius: '30px',
        }}
        direction="column"
        alignItems="center"
      >
        <Stack
          width="95%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            to={MODULES.inicio}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img
              src={menuActiveColor ? IMG.logotipoSvg : IMG.logotipoAzulPng}
              alt="headerImg"
              style={{
                width: '100px',
                paddingLeft: '1vw',
                marginTop: '10px',
                marginBottom: '5px',
              }}
            />
          </Link>

          <IconButton
            fontSize="inherit"
            aria-label="menu"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() =>
              dispatch({
                type: 'IS_MENU_LANDING',
                payload: !landingStore.isMenu,
              })
            }
          >
            {landingStore.isMenu ? (
              <CloseIcon
                sx={{
                  fontSize: '31px',
                  animation: `${zoom} 0.5s ease`,
                  color: !menuActiveColor ? COLORS.AZUL_OSCURO : COLORS.BLANCO,
                }}
              />
            ) : (
              <img
                src={!menuActiveColor ? IMG.menuAzulPng : IMG.menuPng}
                alt="menu"
                style={{
                  width: '25px',
                  padding: '1.2vw 0vw',
                }}
              />
            )}
          </IconButton>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            position: 'relative',
            width: '100%',
            height: landingStore.isMenu ? '33vh' : 0,
            backgroundColor: !menuActiveColor
              ? COLORS.BLANCO
              : COLORS.AZUL_OSCURO,
            borderRadius: '6vw',
            transition: 'height 0.2s ease-in-out',
          }}
        >
          {body}
        </Stack>
      </Stack>
    </Box>
  );
};

export default MenuMobileComponent;
