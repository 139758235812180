import {
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  Stack,
} from '@mui/material';
import React from 'react';
import { COLORS } from '../../../helpers';
import * as IMG from '../../../static/images';
import { onClickWasa } from '../LandingView';

const ButtonWasa = ({ t }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Button
      sx={{
        height: isMobile ? '50px' : '3.3vw',
        borderRadius: isMobile ? '25px' : '2vw',
        backgroundColor: COLORS.VERDE_WASA,
        color: COLORS.BLANCO,
        textTransform: 'none',
        transition:
          'transform 0.3s ease-in-out, background-color 0.5s ease-in-out',
        '&:hover': {
          backgroundColor: COLORS.VERDE_WASA,
          color: COLORS.NEGRO,
          transform: 'scale(1.02)',
        },
        padding: isMobile ? '0 5vw' : '0 1.2vw',
        fontFamily: 'Bold',
        fontSize: isMobile ? '7vw' : '1.5vw',
        marginTop: isMobile ? '3vw' : '2vw',
      }}
      onClick={() => onClickWasa(isMobile)}
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <img
          src={IMG.wasaSvg}
          alt="whatsapp"
          style={{
            width: isMobile ? '24pt' : '2.3vw',
            marginRight: isMobile ? '10px' : '1vw',
          }}
        />
        <Typography
          fontFamily="bold"
          color={COLORS.BLANCO}
          fontSize={isMobile ? '14pt' : '1.5vw'}
        >
          {t('familia.button')}
        </Typography>
      </Stack>
    </Button>
  );
};

export default ButtonWasa;
